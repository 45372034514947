import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import ApiService from '../api/ApiService'
import notification from '../helpers/notification'
import MetaData from '../helpers/MetaData'
import PageHeader from '../components/common/PageHeader'
import GoToTop from "../helpers/GoToTop";

const YatraThankYouPage = () => {
    const { id } = useParams()
    const location = useLocation();
    const [yatraDetails, setYatraDetails] = useState(null)
    const [top, setTop] = useState(0);

    const fetchPaymentDetails = async () => {
        try {
            const res = await ApiService.fetchYatraDetails(id)
            console.log(res)
            setYatraDetails(res)
        } catch (err) {
            console.log(err)
            notification('error', 'Something went wrong')
        }
    }
    const sectionRef = useCallback((node) => {
        if (node) {
            let headerHeight;
            if (window.innerWidth > 991) {
                headerHeight = 140;
            } else if (window.innerWidth > 576) {
                headerHeight = 80;
            } else {
                headerHeight = 73;
            }
            setTop(node?.offsetTop - headerHeight);
        }
    });
    useEffect(() => {
        fetchPaymentDetails()
    }, [])
    // const yatraTitle = location.state && location.state.yatraTitle;
    const { yatraTitle, pay } = location.state || {};
    return (
        <>
            <MetaData title="Thank You - ISKCON of Bhiwandi" />
            <PageHeader title="Thank You" imgSrc="/images/page-header/25.jpg" />
            <section className="thank-you-container" ref={sectionRef}>
                <div className="container">
                    {
                        yatraDetails?.paymentStatus === "success" ?

                            yatraDetails?.formType === "Registration Payment" ?



                                <div id="scrollToThankYouPage" className="row align-items-center justify-content-between">
                                    <div className="text-center">
                                        <h2 className='text-center'>Thank you for your registration for the {yatraDetails?.yatraName}.</h2>
                                        <p className="mt-4" style={{ textAlign: 'center' }}>Dear {yatraDetails?.name} Ji,</p>


                                        <p className='mt-0' style={{ textAlign: 'center' }}>
                                            We will share further details with you soon and we will get back to you regarding confirmation of rooms.
                                        </p>




                                        <p className='mt-0' style={{ textAlign: 'center' }}>In case you have any queries you can reach out to our volunteers at <a href="tel:+9175263178">91752 63178</a>. </p>



                                        <p className='mt-0' style={{ textAlign: 'center' }}>
                                            Hare Krishna.
                                        </p>
                                    </div>
                                </div>


                                :
                                <div id="scrollToThankYouPage" className="row align-items-center justify-content-between">
                                    <div className="text-center">
                                        <h2 className='text-center'>Thank You!</h2>
                                        <p className="mt-4" style={{ textAlign: 'center' }}>Dear {yatraDetails?.name} Ji,</p>


                                        <p className='mt-0' style={{ textAlign: 'center' }}>
                                            We will get back to you soon on further details of the yatra and the itinerary.
                                        </p>




                                        <p className='mt-0' style={{ textAlign: 'center' }}>In case you have any queries you can reach out to our volunteers at   <a href="tel:+9175263178">91752 63178</a>. </p>



                                        <p className='mt-0' style={{ textAlign: 'center' }}>
                                            Hare Krishna.
                                        </p>
                                    </div>
                                </div>

                            :


                            <div className="row align-items-center">
                                <div className="col-8 mx-auto">
                                    <div className="thank-you-text text-center">
                                        <div className="heading text-center">
                                            <h2 className="text-center head-1">Payment Pending <img src="/images/common/pending.gif" alt="" /> </h2>
                                            <p className="mt-4">Dear {yatraDetails?.name} Ji,</p>
                                            <p className='mt-0'>
                                                Your payment is being processed.
                                            </p>
                                            <p className='mt-0'>
                                                Hare Krishna.
                                            </p>
                                        </div>
                                        <Link to="/join-family" className='box-hover custom-btn mt-4 d-inline-block text-uppercase'>Join the family of Krishna</Link>
                                    </div>
                                </div>
                            </div>
                    }

                </div>
            </section >
            <GoToTop top={top} />
        </>
    );
};

export default YatraThankYouPage;

